/* @jsx jsx */
import { jsx, Heading, Box, Grid, Text } from "theme-ui";
import { alpha, lighten } from "@theme-ui/color";
import { Link } from "gatsby";
import { FaChevronLeft } from "react-icons/fa";
import { Helmet } from "react-helmet";
import ConfLogo from "../components/ConfLogo";
import Layout from "../components/Layout";
import HubspotForm from "../components/HubspotForm";
import ConfPass from "../components/ConfPass";
import Copy from "../components/Copy";

const containerStyles = {
  pt: `4`,
  pb: [`4`, `6`],
  px: [4, 5, 6, 0],
  alignItems: `center`,
  gridGap: [`3`, `4`, `5`],
  gridTemplateRows: `repeat(4, auto)`,
};

const headingStyles = {
  fontSize: [`6`, `7`],
  textTransform: `capitalize`,
  textAlign: `center`,
};

const minorHeaderStyles = {
  display: `flex`,
  justifyContent: `space-between`,
};

const HEADER_ITEM_WIDTH = 80;
const backLinkStyles = {
  width: HEADER_ITEM_WIDTH,
  fontSize: `2`,
  display: `flex`,
  alignItems: `center`,
  variant: `text.link`,
};

const emptyBoxStyles = {
  width: HEADER_ITEM_WIDTH, // making this the same size
};

const shareStyles = {
  fontSize: `3`,
};
const shareTextStyles = {
  textAlign: `center`,
};
const shareLinkStyles = {
  backgroundColor: alpha(`magenta`, 0.25),
  borderRadius: `3`,
  display: `flex`,
  alignItems: `center`,
  justifyContent: `space-between`,
  pl: `3`,
};
const codeStyles = {
  fontFamily: `monospace`,
  overflowX: `auto`,
  color: lighten(`magenta`, 0.25),
};

const confPassWrapperStyles = {
  position: `relative`,
  display: `flex`,
  justifyContent: `center`,
};

const confPassOverlayStyles = {
  position: `absolute`,
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  background: (t) =>
    `linear-gradient(to bottom, ${t.colors.background}, transparent 5%)`,
};

const formNoteStyles = {
  fontSize: `3`,
  textAlign: `center`,
};
const homepageLinkStyles = {
  variant: `text.link`,
  color: `magenta`,
};

const RegistrantFallbackPage = () => {
  const ogImageUrl = `https://storage.googleapis.com/gatsbyconf-2021-passes-source/fallback/%20GatsbyConf_Fallback_Conference_Pass.png.`;
  const pageUrl = `https://gatsbyconf.com/`;

  return (
    <Layout>
      <Helmet>
        <title>Welcome to GatsbyConf</title>
        <meta name="title" content={`Welcome to GatsbyConf`} />
        <meta
          name="description"
          content={`Join us at GatsbyConf online from March 2-3. A free event focused on the future web.`}
        />
        <meta name="image" content={ogImageUrl} />
        <meta itemProp="image" content={ogImageUrl} />
        <meta name="twitter:image" content={ogImageUrl} />
        <meta property="og:image" content={ogImageUrl} />
      </Helmet>
      <Grid sx={containerStyles} flexDirection="column">
        <Box sx={minorHeaderStyles}>
          <Link to="/" sx={backLinkStyles}>
            <FaChevronLeft /> Go back
          </Link>
          <Box>
            <ConfLogo />
          </Box>
          <Box sx={emptyBoxStyles} />
        </Box>
        <Heading sx={headingStyles}>
          <Text variant="gradient">Get Ready For</Text>
          <Text>GatsbyConf</Text>
        </Heading>
        <Box sx={formNoteStyles}>
          <Text>
            Find out more about <b>GatsbyConf</b> on{" "}
            <Link to="/" sx={homepageLinkStyles}>
              our homepage
            </Link>
            .
          </Text>
        </Box>
        <Box sx={confPassWrapperStyles}>
          <Box sx={confPassOverlayStyles}></Box>
          <ConfPass firstName="Jay" lastName="Gatsby" number={1} />
        </Box>
        <Box sx={shareStyles}>
          <Text sx={shareTextStyles}>
            Invite your friends and colleagues by sharing the link to the
            homepage:
          </Text>
          <pre sx={shareLinkStyles}>
            <code sx={codeStyles}>{pageUrl}</code>
            <Copy content={pageUrl} />
          </pre>
        </Box>
        <HubspotForm
          title={`Join us by claiming your ticket!`}
          decorativeText={`6000+ people have already signed up!`}
        />
      </Grid>
    </Layout>
  );
};

export default RegistrantFallbackPage;
